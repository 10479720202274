"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCourseMeta = getCourseMeta;
exports.getCatalogCourses = getCatalogCourses;
exports.createCourse = createCourse;
exports.getMFEURL = getMFEURL;
exports.getAuthEdxIframeToken = getAuthEdxIframeToken;
exports.getCourseOutline = getCourseOutline;
exports.fetchUserCourses = fetchUserCourses;
exports.fetchEnrollStatus = fetchEnrollStatus;
exports.enrollToCourse = enrollToCourse;
exports.adminEnrollToCourse = adminEnrollToCourse;
exports.unenrollFromCourse = unenrollFromCourse;
exports.get_user_course_grade_progress = get_user_course_grade_progress;
exports.getCourseTabs = getCourseTabs;
exports.getCourseProgress = getCourseProgress;
exports.getCourseBlocks = getCourseBlocks;
exports.getStudentCourseContent = getStudentCourseContent;
const http_1 = require("../http");
const config_1 = require("../config");
const http = new http_1.default();
function getCourseMeta(data, callback = null) {
    http.get(config_1.default.IBL_EDX.GET_COURSE_METADATA, data, (data) => {
        callback && callback(data);
    }, (error) => {
        console.error('Error occurred:', error);
    }, false, 'IBL_LMS_URL');
}
function getCatalogCourses(data, successCb, errorCb) {
    http.get(config_1.default.IBL_EDX.GET_CATALOG_COURSES, data, (data) => {
        successCb && successCb(data);
    }, (error) => {
        errorCb && errorCb(error);
    }, false, 'IBL_LMS_URL');
}
function createCourse(data, callback = null) {
    http.post(config_1.default.IBL_EDX.CREATE_COURSE, data, (data) => {
        callback && callback(data);
    }, (error) => {
        console.error('Error occurred:', error);
    }, false, 'IBL_CMS_URL');
}
function getMFEURL(data, callback = null) {
    const { course_id: courseId, mfe_url: mfeUrl, xblock_id: xblockId, sequential_id: sequentialId, } = data;
    let basePath = `${mfeUrl}/`;
    switch (xblockId) {
        case 'forum':
            return `${basePath}discussions/${courseId}/posts`;
        case 'notes':
            return `${basePath}courses/${courseId}/edxnotes`;
        case 'instructor':
            return `${basePath}courses/${courseId}/instructor`;
        default:
            // Correctly handle the URL path whether or not sequentialId is defined
            const coursePath = `learning/course/${courseId}/`;
            return sequentialId
                ? `${basePath}${coursePath}${sequentialId}/${xblockId}`
                : `${basePath}${coursePath}${xblockId}`;
    }
}
function getAuthEdxIframeToken(data, callback = null) {
    http.post(config_1.default.IBL_EDX.GET_EDX_AUTH_TOKEN_URL, data, (data) => {
        if (callback) {
            callback(data);
        }
    }, (error) => {
        console.error('Error occurred:', error);
    }, false, 'IBL_LMS_URL');
}
/**
 * Gets user's course outline
 * @function getCourseOutline
 * @memberof ibledxcourses
 *
 * @param {Object} data
 * @param {String} [data.course_id] Course ID of the User
 * @param {callback} successCb Callback function for successful request
 * @param {callback} errorCb Callback function for error in request
 *
 * @example <caption>Gets user's course outline</caption>
 * This usage:
 *
 * ```js
 * ibledxcourses.getCourseOutline(
 *    {
 *      course_id: "example course ID",
 *    },
 *    (data) => console.log(data),
 *    (error) => console.error(error)
 * )
 * ```
 */
function getCourseOutline(data, callback = null) {
    http.get(config_1.default.IBL_EDX.GET_COURSE_OUTLINE(data.course_id), data, (data) => {
        if (callback) {
            callback(data);
        }
    }, (error) => {
        console.error('Error occurred:', error);
    }, false, 'IBL_LMS_URL');
}
function fetchUserCourses(data, callback = null) {
    this.http.get(config_1.default.IBL_EDX.FETCH_USER_COURSES, data, (data) => {
        if (callback) {
            callback(data);
        }
    }, (error) => {
        console.error('Error occurred:', error);
    }, false, 'IBL_LMS_URL');
}
function fetchEnrollStatus(data, callback = null) {
    http.get(config_1.default.IBL_EDX.FETCH_ENROLL_STATUS, data, (data) => {
        if (callback) {
            callback(data);
        }
    }, (error) => {
        console.error('Error occurred:', error);
    }, false, 'IBL_LMS_URL');
}
function enrollToCourse(data, successCb = null, errorCb = null) {
    data = JSON.stringify({
        course_details: data,
    });
    http.post(config_1.default.IBL_EDX.ENROLL_TO_COURSE, data, (data) => {
        if (successCb) {
            successCb(data);
        }
    }, (error) => {
        errorCb && errorCb(error);
    }, false, 'IBL_LMS_URL');
}
function adminEnrollToCourse(data, successCb = null, errorCb = null) {
    http.post(config_1.default.IBL_EDX.ADMIN_ENROLL_TO_COURSE, data, (data) => {
        if (successCb) {
            successCb(data);
        }
    }, (error) => {
        errorCb && errorCb(error);
    }, false, 'IBL_LMS_URL');
}
function unenrollFromCourse(data, successCb = null, errorCb = null) {
    http.post(config_1.default.IBL_EDX.UNENROLL_FROM_COURSE, data, (data) => {
        if (successCb) {
            successCb(data);
        }
    }, (error) => {
        errorCb && errorCb(error);
    }, false, 'IBL_LMS_URL');
}
function get_user_course_grade_progress(data, successCb = null, errorCb = null) {
    http.get(config_1.default.IBL_EDX.GET_USER_COURSE_GRADE_PROGRESS(data['course_id'], data['user_id']), {}, (data) => {
        if (successCb) {
            successCb(data);
        }
    }, (error) => {
        errorCb && errorCb(error);
    }, false, 'IBL_LMS_URL');
}
/**
 * Retrieves the course tabs
 * @kind function
 * @name getCourseTabs
 * @memberof ibledxcourses
 *
 * @param {Object} data
 * @param {String} data.course_id Course ID
 * @param {callback} successCb
 *
 * @example <caption>Get the Course tabs</caption>
 * This usage:
 *
 * ```js
 * ibledxcourses.getCourseTabs(
 *   {
 *        course_id: "course-v1:IBL+1+1",
 *    },
 *    (data) => console.log(data)
 * )
 * ```
 */
function getCourseTabs(data, successCb = null) {
    http.get(config_1.default.IBL_EDX.GET_COURSE_TABS(data.course_id), data, (data) => {
        if (successCb) {
            successCb(data);
        }
    }, (error) => {
        console.error('Error occurred:', error);
    }, false, 'IBL_LMS_URL');
}
/**
 * Retrieves the course progress of a user
 * @kind function
 * @name getCourseProgress
 * @memberof ibledxcourses
 *
 * @param {Object} data
 * @param {String} data.course_id Course ID
 * @param {callback} successCb
 *
 * @example <caption>Get the Course progress of a user</caption>
 * This usage:
 *
 * ```js
 * ibledxcourses.getCourseProgress(
 *   {
 *        course_id: "course-v1:IBL+1+1",
 *    },
 *    (data) => console.log(data)
 * )
 * ```
 */
function getCourseProgress(data, successCb = null, errorCb = null) {
    http.get(config_1.default.IBL_EDX.GET_COURSE_PROGRESS + data.course_id, null, (data) => {
        successCb && successCb(data);
    }, (error) => {
        errorCb && errorCb(error);
    }, false, 'IBL_LMS_URL');
}
/**
 * Retrieves the course blocks for a user
 * @kind function
 * @name getCourseBlocks
 * @memberof ibledxcourses
 *
 * @param {Object} data
 * @param {String} data.course_id Course ID
 * @param {String} data.username Course ID
 * @param {callback} successCb
 * @param {callback} errorCb
 *
 * @example <caption>Get the Course blocks for a user</caption>
 * This usage:
 *
 * ```js
 * ibledxcourses.getCourseBlocks(
 *   {
 *        course_id: "course-v1:IBL+1+1",
 *        username: <username>
 *    },
 *    (data) => console.log(data)
 * )
 * ```
 */
function getCourseBlocks(data, successCb = null, errorCb = null) {
    http.get(config_1.default.IBL_EDX.GET_COURSE_BLOCKS(data.course_id, data.username), null, (data) => {
        successCb && successCb(data);
    }, (error) => {
        errorCb && errorCb(error);
    }, false, 'IBL_LMS_URL');
}
/**
 * Retrieves the course blocks for a user
 * @kind function
 * @name getStudentCourseContent
 * @memberof ibledxcourses
 *
 * @param {Object} data
 * @param {String} data.course_id Course ID
 * @param {callback} successCb
 * @param {callback} errorCb
 *
 * @example <caption>Get the Course Content for a user</caption>
 * This usage:
 *
 * ```js
 * ibledxcourses.getStudentCourseContent(
 *   {
 *        course_id: "course-v1:IBL+1+1"
 *    },
 *    (data) => console.log(data)
 * )
 * ```
 */
function getStudentCourseContent(data, successCb = null, errorCb = null) {
    http.get(config_1.default.IBL_EDX.GET_STUDENT_CONTENT(data.course_id), null, (data) => {
        successCb && successCb(data);
    }, (error) => {
        errorCb && errorCb(error);
    }, false, 'IBL_LMS_URL');
}
