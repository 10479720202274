"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchImageByUsername = fetchImageByUsername;
exports.fetchCertificateTemplate = fetchCertificateTemplate;
exports.fetchCustomRegistrationFields = fetchCustomRegistrationFields;
exports.fetchCustomCSS = fetchCustomCSS;
exports.fetchMatomoAdminToken = fetchMatomoAdminToken;
exports.uploadIMGtoWeb = uploadIMGtoWeb;
exports.addMatomoScript = addMatomoScript;
const http_1 = require("../http");
const config_1 = require("../config");
const utils_1 = require("../utils");
const http = new http_1.default();
function adminGetTenantName(callback = null) {
    http.get(config_1.default.IBL_WEB.ADMIN_GET_TENANT_NAME, null, (data) => {
        callback && callback(data);
    }, (error) => {
        console.error("Error occurred:", error);
    });
}
function fetchImageByUsername(data = null, callback = null) {
    http.get(config_1.default.IBL_WEB.FETCH_IMAGE_BY_USERNAME, data, (data) => {
        callback && callback(data);
    }, (error) => {
        console.error("Error occurred:", error);
    }, false);
}
function fetchCertificateTemplate(callback = null, includeAuthorizationHeader = true) {
    http.get(config_1.default.IBL_WEB.FETCH_CERTIFICATE_TEMPLATE, null, (data) => {
        callback && callback(data);
    }, (error) => {
        console.error("Error occurred:", error);
    }, includeAuthorizationHeader);
}
function fetchCustomRegistrationFields(callback = null, includeAuthorizationHeader = false) {
    http.get(config_1.default.IBL_WEB.FETCH_CUSTOM_REGISTRATION_FIELDS, null, (data) => {
        callback && callback(data);
    }, (error) => {
        console.error("Error occurred:", error);
    }, includeAuthorizationHeader);
}
function fetchCustomCSS(callback = null, includeAuthorizationHeader = false) {
    http.get(config_1.default.IBL_WEB.FETCH_CUSTOM_CSS, null, (data) => {
        callback && callback(data);
    }, (error) => {
        console.error("Error occurred:", error);
    }, includeAuthorizationHeader);
}
function fetchMatomoAdminToken(data, callback = null) {
    http.get(config_1.default.IBL_WEB.FETCH_MATOMO_ADMIN_TOKEN, data, (data) => {
        callback && callback(data);
    }, (error) => {
        console.error("Error occurred:", error);
    });
}
function uploadIMGtoWeb(data, callback = null) {
    http.post(config_1.default.IBL_WEB.UPLOAD_IMG_TO_WEB, data, (data) => {
        callback && callback(data);
    }, (error) => {
        console.error("Error occurred:", error);
    }, true);
}
function addMatomoScript(data = null, callback = null) {
    let base_domain = (0, utils_1.removeSubdomains)();
    let userData = JSON.parse(localStorage.getItem("userData"));
    if (!userData) {
        return;
    }
    let userId = userData ? userData.user_nicename : "";
    let axd_url = data.matomo_url;
    let current_tenant = JSON.parse(localStorage.getItem("current_tenant"));
    let org = current_tenant ? (current_tenant.org || "main") : "";
    if (org === "main") {
        org = 1;
    }
    var scriptContent = `
  var _paq = window._paq = window._paq || [];
  _paq.push(['setCookieDomain', '*.${base_domain}']);
  _paq.push(['setDomains', '*.${base_domain}']);
  ${userId ? `_paq.push(['setUserId', '${userId}']);` : ""}
  _paq.push(['trackPageView']);
  _paq.push(['trackAllContentImpressions']);
  _paq.push(['trackVisibleContentImpressions']);
  _paq.push(['enableHeartBeatTimer']);
  (function() {
      var u = "${axd_url}/matomo.php";
      _paq.push(['setTrackerUrl', u]);
      _paq.push(['setSiteId', '${org}']);
      var d = document,
          g = d.createElement('script'),
          s = d.getElementsByTagName('script')[0];
      g.async = true;
      g.src = u.replace(/\.php$/, '.js');
      s.parentNode.insertBefore(g, s);
  })();
`;
    var scriptElement = document.createElement("script");
    scriptElement.type = "text/javascript";
    scriptElement.textContent = scriptContent;
    document.head.appendChild(scriptElement);
    // Optional callback execution if provided
    if (callback && typeof callback === "function") {
        callback();
    }
}
