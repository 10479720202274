"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOrgCredentials = getOrgCredentials;
exports.getUserCredentials = getUserCredentials;
exports.getCredential = getCredential;
exports.getOrgIssuer = getOrgIssuer;
exports.createCredential = createCredential;
exports.getCourseCredentials = getCourseCredentials;
exports.issueCredential = issueCredential;
exports.getUserAssertion = getUserAssertion;
exports.getPublicAssertion = getPublicAssertion;
const http_1 = require("../http");
const config_1 = require("../config");
const http = new http_1.default();
/**
 * Retrieves the credentials for an organisation
 * @kind function
 * @name getOrgCredentials
 *
 * @param {Object} data
 */
function getOrgCredentials(data, successCb = null) {
    http.get(config_1.default.IBL_DM.GET_ORG_CREDENTIALS, data, (data) => {
        successCb(data);
    }, (error) => {
        console.error('Error occurred:', error);
    });
}
function getUserCredentials(data, successCb = null, includeAuthorizationHeader = true) {
    http.get(config_1.default.IBL_DM.GET_USER_CREDENTIALS, data, (data) => {
        successCb(data);
    }, (error) => {
        console.error('Error occurred:', error);
    }, includeAuthorizationHeader, 'IBL_DM_URL');
}
function getCredential(data, successCb = null) {
    http.get(config_1.default.IBL_DM.GET_CREDENTIAL(data.entity_id), data, (data) => {
        successCb(data);
    }, (error) => {
        console.error('Error occurred:', error);
    });
}
function getOrgIssuer(org, successCb = null) {
    http.get(config_1.default.IBL_DM.GET_ORG_ISSUER(org), {}, (data) => {
        successCb(data);
    }, (error) => {
        console.error('Error occurred:', error);
    });
}
function createCredential(data, successCb = null) {
    http.post(config_1.default.IBL_DM.CREATE_CREDENTIAL, data, (data) => {
        successCb(data);
    }, (error) => {
        console.error('Error occurred:', error);
    });
}
function getCourseCredentials(data, successCb = null) {
    http.get(config_1.default.IBL_DM.GET_COURSE_CREDENTIALS, data, (data) => {
        successCb(data);
    }, (error) => {
        console.error('Error occurred:', error);
    });
}
function issueCredential(entityId, data, successCb = null) {
    http.post(config_1.default.IBL_DM.ISSUE_CREDENTIAL(entityId), data, (data) => {
        successCb(data);
    }, (error) => {
        console.error('Error occurred:', error);
    });
}
function getUserAssertion(id, successCb = null) {
    http.get(config_1.default.IBL_DM.GET_USER_ASSERTION(id), {}, (data) => {
        successCb(data);
    }, (error) => {
        console.error('Error occurred:', error);
    }, false);
}
function getPublicAssertion(data, successCb = null, errorCb = null) {
    http.get(config_1.default.IBL_DM.GET_PUBLIC_ASSERTION + data.id, null, (data) => {
        successCb(data);
    }, (error) => {
        errorCb();
    }, false, 'IBL_DM_URL');
}
