"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUserCredential = getUserCredential;
exports.getPublicAssertion = getPublicAssertion;
const http_1 = require("../http");
const config_1 = require("../config");
const http = new http_1.default();
/**
 * Retrieves the credential info for of a user
 * @kind function
 * @name getUserCredential
 *
 * @param {Object} data
 */
function getUserCredential(data, successCb = null) {
    http.get(config_1.default.IBL_EDX.GET_USER_CREDENTIAL(data.uuid), data, (data) => {
        successCb(data);
    }, (error) => {
        console.error('Error occurred:', error);
    }, false, 'IBL_LMS_URL');
}
function getPublicAssertion(data, successCb = null, errorCb = null) {
    http.get(config_1.default.IBL_EDX.GET_PUBLIC_ASSERTION + data.id, null, (data) => {
        successCb(data);
    }, (error) => {
        errorCb();
    }, false, 'IBL_LMS_URL');
}
