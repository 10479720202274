"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUserSkills = getUserSkills;
exports.getUserSelfReportedSkills = getUserSelfReportedSkills;
exports.getOrgSkills = getOrgSkills;
exports.addSelfReportedSkill = addSelfReportedSkill;
exports.addDesiredSkill = addDesiredSkill;
exports.getBlockSkillPointInfo = getBlockSkillPointInfo;
exports.getUserSkillPointInfo = getUserSkillPointInfo;
const http_1 = require("../http");
const config_1 = require("../config");
const http = new http_1.default();
function getUserSkills(data, successCB = null, errorCB = null) {
    http.get(config_1.default.IBL_DM.GET_USER_SKILLS, data, (data) => {
        successCB && successCB(data);
    }, (error) => {
        errorCB && errorCB(error);
    }, true, 'IBL_DM_URL');
}
function getUserSelfReportedSkills(data, successCB = null, errorCB = null) {
    http.get(config_1.default.IBL_DM.GET_USER_SELF_REPORTED_SKILLS, data, (data) => {
        successCB && successCB(data);
    }, (error) => {
        error && errorCB(error);
    }, true, 'IBL_DM_URL');
}
function getOrgSkills(data = null, successCB = null, errorCB = null) {
    http.get(config_1.default.IBL_DM.GET_ORG_SKILLS, data, (data) => {
        successCB && successCB(data);
    }, (error) => {
        errorCB && errorCB(error);
    }, true, 'IBL_DM_URL');
}
function addSelfReportedSkill(data, successCB = null, errorCB = null) {
    http.post(config_1.default.IBL_DM.ADD_SELF_REPORTED_SKILL, data, (data) => {
        successCB && successCB(data);
    }, (error) => {
        error && errorCB(error);
    }, true, 'IBL_DM_URL');
}
function addDesiredSkill(data, successCB = null, errorCB = null) {
    http.post(config_1.default.IBL_DM.ADD_DESIRED_SKILL, data, (data) => {
        successCB && successCB(data);
    }, (error) => {
        errorCB && errorCB(error);
    }, true, 'IBL_DM_URL');
}
/**
 * Retrieves the block skill point info
 * @kind function
 * @name getBlockSkillPointInfo
 * @memberof ibldmskills
 *
 * @param {Object} data
 * @param {String} data.block_id Block ID
 * @param {callback} successCb
 *
 * @example <caption>Get the block skill point info</caption>
 * This usage:
 *
 * ```js
 * ibldmskills.getBlockSkillPointInfo(
 *   {
 *        block_id: "block-v1:IBL+1+1",
 *    },
 *    (data) => console.log(data)
 * )
 * ```
 */
function getBlockSkillPointInfo(data, successCB = null, errorCB = null) {
    http.get(config_1.default.IBL_DM.GET_BLOCK_SKILL_POINT_INFO, data, (data) => {
        successCB && successCB(data);
    }, (error) => {
        errorCB && errorCB(error);
    }, true, 'IBL_DM_URL');
}
/**
 * Retrieves the user skill point info
 * @kind function
 * @name getUserSkillPointInfo
 * @memberof ibldmskills
 *
 * @param {Object} data
 * @param {String} data.username User's username
 * @param {callback} successCb
 *
 * @example <caption>Get the User skill point info</caption>
 * This usage:
 *
 * ```js
 * ibldmskills.getUserSkillPointInfo(
 *   {
 *        username: "example",
 *    },
 *    (data) => console.log(data)
 * )
 * ```
 */
function getUserSkillPointInfo(data, successCB = null, errorCB = null) {
    http.get(config_1.default.IBL_DM.GET_USER_SKILL_POINT_INFO, data, (data) => {
        successCB && successCB(data);
    }, (error) => {
        errorCB && errorCB(error);
    }, true, 'IBL_DM_URL');
}
