"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var userData = localStorage.getItem('userData');
var tenant = localStorage.getItem('tenant') || 'main';
const endpoint = {
    LOGIN: '/api/user/v2/account/login_session/',
    REGISTRATION: '/api/user/v2/account/registration/',
    LOGOUT: '/ibl/ai/sso/backend/edx/end-user-session',
    IBL_DM: {
        GET_USER_COURSES: '/api/catalog/enrollment/courses/search/',
        GET_ORG_CREDENTIALS: '/wp-json/cred/api/v2/credentials/',
        GET_USER_CREDENTIALS: '/api/cred/v2/assertions/',
        GET_CREDENTIAL: (entity_id) => '/wp-json/cred/api/v2/credentials/' + entity_id + '/',
        GET_ORG_ISSUER: (org) => `/api/v2/issuers/${org}`,
        CREATE_CREDENTIAL: '/api/v2/credentials/',
        GET_COURSE_CREDENTIALS: '/api/v2/credentials/',
        ISSUE_CREDENTIAL: (entityId) => `/api/v2/credentials/${entityId}/assertions/`,
        GET_USER_ASSERTION: (id) => `/api/v2/assertions/${id}`,
        CREATE_PATHWAY: '/api/catalog/pathways/',
        GET_PATHWAY: '/api/catalog/pathways/',
        GET_USER_PROGRAMS: '/api/catalog/enrollment/programs/',
        FETCH_USER_PROGRESS: '/api/catalog/milestones/completions/course/catalog/',
        CHECK_USER_INVITATION: '/api/catalog/invitations/platform/',
        CREATE_USER_ATTENDANCE: '/api/attendance/user/course/block/',
        GET_USER_SKILLS: '/api/catalog/skills/desired/',
        GET_USER_SELF_REPORTED_SKILLS: '/api/catalog/skills/reported/',
        GET_ORG_SKILLS: '/api/catalog/skills/',
        ADD_SELF_REPORTED_SKILL: '/api/catalog/skills/reported/',
        ADD_DESIRED_SKILL: '/api/catalog/skills/desired/',
        INVITE_USER_TO_PLATFORM: '/api/catalog/invitations/platform/',
        GET_PLATFORM_SITE_PUBLIC_CONFIG: '/wp-json/api/ibl/v1/tenant/public-config/',
        LINK_USER_TO_PLATFORM: '/wp-json/api/ibl/v1/user/platform/link/',
        FETCH_PLATFORM_INVITATIONS: `/api/catalog/invitations/platform/`,
        FETCH_COURSE_INVITATIONS: '/api/catalog/invitations/course/',
        FETCH_PROGRAM_INVITATIONS: '/api/catalog/invitations/program/',
        INVITE_USER_TO_COURSE: '/api/catalog/invitations/course/',
        INVITE_USER_TO_PROGRAM: '/api/catalog/invitations/program/',
        CREATE_PLATFORM_BULK_INVITATIONS: '/api/catalog/invitations/platform/bulk/',
        CREATE_PLATFORM_BLANK_INVITATIONS: '/api/catalog/invitations/platform/blank/',
        CREATE_COURSE_BULK_INVITATIONS: '/api/catalog/invitations/course/bulk/',
        CREATE_COURSE_BLANK_INVITATIONS: '/api/catalog/invitations/course/blank/',
        CREATE_PROGRAM_BULK_INVITATIONS: '/api/catalog/invitations/program/bulk/',
        CREATE_PROGRAM_BLANK_INVITATIONS: '/api/catalog/invitations/program/blank/',
        REDEEM_PLATFORM_INVITATIONS: '/api/catalog/invitations/platform/redeem/',
        REDEEM_COURSE_INVITATIONS: '/api/catalog/invitations/course/redeem/',
        REDEEM_PROGRAM_INVITATIONS: '/api/catalog/invitations/program/redeem/',
        FETCH_USER_PLATFORM_LINK: '/api/ibl/core/users/platforms/',
        UPDATE_USER_PROGRAM_STATUS: '/api/catalog/enrollment/programs/',
        GET_USER_COURSE_COMPLETION: '/api/catalog/milestones/completions/course/manage/',
        GET_USER_RESOURCE_COMPLETION: '/api/catalog/milestones/completions/course/manage/',
        GET_BLOCK_SKILL_POINT_INFO: '/api/catalog/milestones/skill_points/block',
        GET_COURSE_SKILL_POINT_INFO: '/api/catalog/milestones/skill_points/course',
        GET_USER_SKILL_POINT_INFO: '/api/catalog/milestones/skill_points/user/',
        GET_TOKEN: '/api/ibl/manager/consolidated-token/proxy/',
        GET_PUBLIC_ASSERTION: '/api/credentials/public/assertions/',
        GET_USER_METADATA: '/api/core/users/metadata/proxy/',
        CREATE_CHECKOUT_SESSION: (org, username) => `/api/service/orgs/${org}/users/${username}/stripe/checkout-session/`,
    },
    IBL_EDX: {
        GET_COURSE_METADATA: '/api/ibl/v1/course_metadata',
        GET_ROLES: '/api/catalog/roles/',
        CREATE_UPDATE_ROLE: '/api/catalog/roles/',
        GET_DESIRED_ROLES: '/api/catalog/roles/desired/',
        CREATE_UPDATE_DESIRED_ROLES: '/api/catalog/roles/desired/',
        GET_REPORTED_ROLES: '/api/catalog/roles/reported/',
        CREATE_UPDATE_REPORTED_ROLES: '/api/catalog/roles/reported/',
        GET_CSRF_TOKEN: '/csrf/api/v1/token',
        GET_CATALOG_COURSES: '/api/ibl/catalog/courses/',
        CREATE_COURSE: '/api/v1/ibl/course/create',
        GET_MFE_URL: '/wp-json/api/ibl/v1/course/mfe/url/',
        GET_EDX_AUTH_TOKEN_URL: '/ibl/ai/sso/backend/edx/sso-auth-token/generate',
        GET_STUDENT_CONTENT: (courseId) => `/api/ibl-ai-checkpoint/student-content/${courseId}`,
        GET_COURSE_OUTLINE: (courseId) => `/api/ibl/completion/course_outline/${courseId}`,
        GET_COURSE_BLOCKS: (courseId, username) => `/api/courses/v1/blocks/?course_id=${encodeURIComponent(courseId)}&username=${username}&depth=all`,
        FETCH_USER_COURSES: '/api/catalog/enrollment/courses/search/',
        GET_USER_PATHWAYS: '/api/catalog/pathways/',
        GET_USER_METADATA: '/api/ibl/users/manage/metadata/',
        POST_USER_METADATA: '/api/ibl/users/manage/metadata/',
        POST_USER_MANAGE: '/wp-json/api/ibl/v1/users/manage/',
        UPDATE_PLATFORM_CONFIG: '/api/core/platform/config/site/',
        FORGOT_PASSWORD: '/wp-json/api/ibl/v1/users/manage/forgot-password/',
        GET_PUBLIC_DATA_BY_USERNAME: '/api/ibl/users/manage/metadata/public/',
        GET_PLATFORM_USERS: '/api/core/platform/users',
        FETCH_ENROLL_STATUS: '/api/ibl/enrollment/enroll_status',
        GET_MFE_CONTEXT: '/api/mfe_context',
        ENROLL_TO_COURSE: '/api/enrollment/v1/enrollment',
        SEND_EMAIL: '/ibl/api/edx/custom/emails/send',
        ADMIN_ENROLL_TO_COURSE: '/api/ibl/enrollment/enroll',
        UNENROLL_FROM_COURSE: '/api/ibl/enrollment/unenroll/',
        GET_USER_COURSE_GRADE_PROGRESS: (courseId, userId) => `/api/course_home/v1/progress/${courseId}/${userId}`,
        GET_USER_CREDENTIAL: (uuid) => `/api/ibl/credentials/certificates/${uuid}/`,
        RESET_PASSWORD: '/account/password',
        POST_USER_VALIDATION: '/wp-json/api/ibl/v1/user/validation/',
        POST_TENANT_VALIDATION: '/api/ibl/service/tenant/validation/',
        POST_TENANT: '/api/ibl/service/launch/tenant/',
        GET_COURSE_TABS: (courseId) => `/api/course_home/course_metadata/${courseId}`,
        GET_COURSE_PROGRESS: '/api/course_home/progress/',
        PATCH_USER_DETAILS: '/api/user/v1/accounts/',
        UPLOAD_USERS_PROFILE_IMAGE: (username) => `/api/user/v1/accounts/${username}/image`,
        GET_USER_DETAILS: (username) => `/api/user/v1/accounts/${username}`,
        GET_PUBLIC_ASSERTION: '/api/ibl/credentials/certificates/',
        GET_USER_TENANTS: '/api/ibl/users/manage/platform/',
        REGISTER_USER: '/api/user/v2/account/registration/',
    },
    IBL_SEARCH: {
        ORG_SEARCH: '/api/search/search/',
        GET_COURSE_META: '/wp-json/api/ibl/v1/course/metadata/search/',
        GET_ORG_SKILLS: '/wp-json/api/ibl/v1/org/skills/',
    },
    IBL_WEB: {
        ADMIN_GET_TENANT_NAME: '/wp-json/api/ibl/v1/tenant/name/',
        CREATE_CHECKOUT_SESSION: '/wp-json/api/ibl/v1/checkout/session/create/',
        FETCH_ALL_PRODUCTS: '/wp-json/api/ibl/v1/stripe/products/',
        FETCH_PRODUCT_PRICE: '/wp-json/api/ibl/v1/stripe/product/price/',
        FETCH_SESSION_ITEMS: '/wp-json/api/ibl/v1/get-session-items/',
        FETCH_PRODUCT: '/wp-json/api/ibl/v1//stripe/product/',
        REGISTER_WEBHOOK: '/wp-json/api/ibl/v1/stripe/webhook/',
        FETCH_CUSTOMER_ID: '/wp-json/api/ibl/v1/stripe/customer/id/',
        FETCH_IMAGE_BY_USERNAME: '/wp-json/api/ibl/v1/user/image/',
        REGISTER_USER: '/wp-json/api/ibl/v1/authentication/register-user/',
        FETCH_CERTIFICATE_TEMPLATE: '/wp-json/api/ibl/v1/certificate/template/',
        FORGET_PASSWORD: '/wp-json/api/ibl/v1/authentication/forget-password/',
        RESET_PASSWORD: '/wp-json/api/ibl/v1/authentication/reset-password/',
        FETCH_CUSTOM_REGISTRATION_FIELDS: '/wp-json/api/ibl/v1/get-custom-registration-fields/',
        FETCH_CUSTOM_CSS: '/wp-json/api/ibl/v1/get-custom-css/',
        POST_TO_STRIPE: '/wp-json/api/ibl/v1/stripe/post/',
        GET_FROM_STRIPE: '/wp-json/api/ibl/v1/stripe/get/',
        FETCH_MATOMO_ADMIN_TOKEN: '/wp-json/api/ibl/v1/matomo-admin-token',
        UPLOAD_IMG_TO_WEB: '/wp-json/api/ibl/v1/upload-img-to-web/',
    },
    IBL_AXD: {
        LOGIN: '/api/auth/login/',
        TIME_UPDATE: (org) => `/api/analytics/orgs/${org}/time/update/`,
        GET_LEARNER_TOKEN: '/wp-json/api/ibl/v1/axd/learner-token/',
        // Engagement APIs
        GET_ORG_ENGAGEMENT_ACTIVITY: '/wp-json/axd/api/engagement/orgs/' + tenant + '/activity',
        GET_ORG_ENGAGEMENT_TIME_SPENT_PER_COURSE: '/wp-json/axd/api/engagement/orgs/' + tenant + '/time/per-course',
        GET_ORG_ENGAGEMENT_TIME_SPENT_OVER_TIME: '/wp-json/axd/api/engagement/orgs/' + tenant + '/time/over-time',
        GET_ORG_ENGAGEMENT_VIDEO: '/wp-json/axd/api/engagement/orgs/' + tenant + '/videos',
        // Audience APIs
        GET_ORG_AUDIENCE_ACTIVE_USERS_OVER_TIME: (start_date, end_date) => '/wp-json/axd/api/audience/orgs/' +
            tenant +
            '/active-users/over-time?end_date=' +
            end_date +
            '&start_date=' +
            start_date,
        GET_ORG_AUDIENCE_ACTIVE_USERS_PER_COURSE: '/wp-json/axd/api/audience/orgs/' + tenant + '/active-users/per-course',
        GET_ORG_AUDIENCE_ACTIVE_USERS: '/wp-json/axd/api/audience/orgs/' + tenant + '/active-users/users',
        GET_ORG_AUDIENCE_REGISTERED_USERS_OVER_TIME: (start_date, end_date) => '/wp-json/axd/api/audience/orgs/' +
            tenant +
            '/registered-users/over-time?end_date=' +
            end_date +
            '&start_date=' +
            start_date,
        GET_ORG_AUDIENCE_REGISTERED_USERS_PER_COURSE: '/wp-json/axd/api/audience/orgs/' +
            tenant +
            '/registered-users/per-course',
        GET_ORG_AUDIENCE_REGISTERED_USERS: '/wp-json/axd/api/audience/orgs/' + tenant + '/registered-users',
        //Finance APIs
        GET_ORG_FINANCE_PRODUCTS: '/wp-json/axd/api/finance/v1/orgs/' + tenant + '/products',
        GET_ORG_FINANCE_PRODUCT_ORDERS: (product_slug) => '/wp-json/axd/api/finance/v1/products' + product_slug + '/orders',
        GET_ORG_PRODUCT_SALES_OVER_TIME: '/wp-json/axd/api/finance/v1/orgs/' +
            tenant +
            '/products/sales/over-time',
        // Performance APIs
        GET_ORG_PERFORMANCE_GRADE_PER_COURSE: '/wp-json/axd/api/performance/orgs/' + tenant + '/grading/per-course',
        GET_ORG_PERFORMANCE_AVERAGE_GRADE: '/wp-json/axd/api/performance/orgs/' + tenant + '/grading/average',
        //  Per-learner APIs
        GET_ORG_PER_LEARNER_USER_INFO: (username) => '/wp-json/axd/api/perlearner/orgs/' +
            tenant +
            '/users/' +
            username +
            '/info?meta=true',
        GET_ORG_PER_LEARNER_USER_TIME_SPENT_OVER_TIME: (username, start_date, end_date) => '/wp-json/axd/api/perlearner/orgs/' +
            tenant +
            '/users/' +
            username +
            '/overview/time/over-time?end_date=' +
            end_date +
            '&start_date=' +
            start_date,
        GET_ORG_PER_LEARNER_ORG_SKILL_LEADER_BOARD: '/wp-json/axd/api/skills/v1/platform/orgs/' +
            tenant +
            '/skills/percentile/',
        GET_ORG_PER_LEARNER_USER_SKILLS: (username) => '/wp-json/axd/api/skills/v1/skills/users/' + username + '/',
        GET_ORG_PER_LEARNER_USER_SELF_REPORTED_SKILLS: (username) => '/wp-json/axd/api/skills/v1/skills/users/' + username + '/self-reported/',
        GET_ORG_PER_LEARNER_USER_ACTIVITY: (username) => '/wp-json/axd/api/perlearner/orgs/' +
            tenant +
            '/users/' +
            username +
            '/activity/',
        GET_ORG_PER_LEARNER_USERS: (username) => '/wp-json/axd/api/perlearner/orgs/' + tenant + '/users',
        GET_ORG_PER_LEARNER_ENGAGEMENT: (username, start_date, end_date) => '/wp-json/axd/api/perlearner/orgs/' +
            tenant +
            '/users/' +
            username +
            '/overview/time/over-time?start_date=' +
            start_date +
            '&end_date=' +
            end_date,
        GET_ORG_PER_LEARNER_PERFORMANCE_INDEX: (username) => '/wp-json/axd/api/perlearner/orgs/' +
            tenant +
            '/users/' +
            username +
            '/overview/performance-index',
        GET_ORG_PER_LEARNER_AVERAGE_COURSE_GRADE: (username) => '/wp-json/axd/api/perlearner/orgs/' +
            tenant +
            '/users/' +
            username +
            '/overview/grades/average',
        GET_ORG_PER_LEARNER_GRADES_PER_COURSE: (username) => '/wp-json/axd/api/perlearner/orgs/' +
            tenant +
            '/users/' +
            username +
            '/grades/per-course',
        GET_ORG_PER_LEARNER_VIDEO_WATCHED_OVERTIME: (username) => '/wp-json/axd/api/perlearner/orgs/' +
            tenant +
            '/users/' +
            username +
            '/videos/over-time',
        GET_ORG_PER_LEARNER_VIDEO_WATCHED_PER_COURSE: (username) => '/wp-json/axd/api/perlearner/orgs/' +
            tenant +
            '/users/' +
            username +
            '/videos/per-course',
        GET_USER_LAST_ACCESS: (username) => '/wp-json/axd/api/perlearner/orgs/' +
            tenant +
            '/users/' +
            username +
            '/last-access',
        GET_COURSE_PROGRESS: (course_id, username) => '/wp-json/axd/api/platform/orgs/' +
            tenant +
            '/courses/' +
            course_id +
            '/users/' +
            username +
            '/progress/',
    },
    DLI_EDX: {
        GET_COURSE_PRICE: '/api/dli/price/',
        GET_SESSION_URL: (courseId, sessionToken) => `/dli/enrollment/course?course_id=${courseId}&session_token=${sessionToken}`,
        POST_EVENT_ENROLL: (code) => `/dli/enrollment/event?event_code=${code}`,
    },
};
exports.default = endpoint;
