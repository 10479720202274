"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUserPathways = getUserPathways;
const http_1 = require("../http");
const config_1 = require("../config");
const http = new http_1.default();
function getUserPathways(data, successCB = null, errorCB = null) {
    http.get(config_1.default.IBL_EDX.GET_USER_PATHWAYS, data, (data) => {
        successCB && successCB(data);
    }, (error) => {
        errorCB && errorCB(error);
    }, true, 'IBL_DM_URL');
}
