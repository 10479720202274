"use strict";
/**
 * @namespace ibledxtenants
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.postTenantValidation = postTenantValidation;
exports.getUserTenants = getUserTenants;
const http_1 = require("../http");
const config_1 = require("../config");
const http = new http_1.default();
/**
 * Post tenant validation
 * @function postTenantValidation
 * @memberof ibledxtenants
 *
 * @see https://github.com/iblai/ibl-dm-multi-services-app/blob/master/USAGE.md#tenant-validation
 *
 * @param {Object} data
 * @param {String} [data.key] Tenant's key
 * @param {String} [data.org] Tenants's org
 * @param {callback} successCb
 * @param {callback} errorCb
 *
 * @example <caption>Post tenant validation</caption>
 * This usage:
 *
 * ```js
 * ibledxtenants.postTenantValidation(
 *    {
 *      key: "somekey",
 *      org: "112824"
 *    },
 *    (data) => console.log(data),
 *    (error) => console.error(error)
 * )
 * ```
 */
function postTenantValidation(data, successCb, errorCb) {
    http.post(config_1.default.IBL_EDX.POST_TENANT_VALIDATION, data, (data) => {
        successCb && successCb(data);
    }, (error) => {
        errorCb && errorCb(error);
    }, false, 'IBL_LMS_URL');
}
function getUserTenants(successCb, errorCb) {
    http.get(config_1.default.IBL_EDX.GET_USER_TENANTS, {}, successCb, errorCb, false, 'IBL_LMS_URL');
}
