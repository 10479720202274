"use strict";
/**
 * @namespace ibldmusers
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUsersManageMetadata = getUsersManageMetadata;
const http_1 = require("../http");
const config_1 = require("../config");
const http = new http_1.default();
/**
 * Get metadata of user
 * @function getUsersManageMetadata
 * @memberof ibldmusers
 *
 *
 * @param {Object} data
 * @param {String} [data.username] User's username
 * @param {callback} successCb
 * @param {callback} errorCb
 *
 * @example <caption>Get metadata of user with username "example"</caption>
 * This usage:
 *
 * ```js
 * ibldmusers.getUsersManageMetadata(
 *    {username: "example", platform_key: "main"},
 *    (data) => console.log(data),
 *    (error) => console.error(error)
 * )
 * ```
 */
function getUsersManageMetadata(data, successCb, errorCb) {
    http.get(config_1.default.IBL_DM.GET_USER_METADATA, data, (data) => {
        successCb && successCb(data);
    }, (error) => {
        errorCb && errorCb(error);
    }, true, 'IBL_DM_URL');
}
