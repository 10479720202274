"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.init = init;
exports.connect = connect;
exports.sendPrompt = sendPrompt;
exports.listen = listen;
exports.remove = remove;
exports.removeAll = removeAll;
exports.close = close;
const $ = require("jquery");
let webSocket = null;
function init(path = '/ws/langflow/') {
    webSocket = $.simpleWebSocket({
        url: window.BASE_WS_URL + path,
        dataType: 'text',
    });
}
function connect(websocketData) {
    webSocket.send(JSON.stringify(websocketData));
}
function sendPrompt(prompt) {
    webSocket.send(prompt);
}
function listen(functioncB) {
    webSocket.listen(functioncB);
}
function remove(listenerCb) {
    webSocket.remove(listenerCb);
}
function removeAll() { }
function close() {
    webSocket.close();
}
