"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.orgSearch = orgSearch;
exports.getCourseMeta = getCourseMeta;
exports.getOrgSkills = getOrgSkills;
exports.populateFilters = populateFilters;
exports.performSearch = performSearch;
exports.clearResults = clearResults;
const config_1 = require("../config");
const http_1 = require("../http");
const http = new http_1.default();
function orgSearch(data, callback = null) {
    http.get(config_1.default.IBL_SEARCH.ORG_SEARCH, data, (data) => {
        callback && callback(data);
    }, (error) => {
        console.error("Error occurred:", error);
    }, false, 'IBL_DM_URL');
}
function getCourseMeta(data, callback = null) {
    http.get(config_1.default.IBL_SEARCH.GET_COURSE_META, data, (data) => {
        callback && callback(data);
    }, (error) => {
        console.error("Error occurred:", error);
    });
}
function getOrgSkills(data, callback = null) {
    http.get(config_1.default.IBL_SEARCH.GET_ORG_SKILLS, data, (data) => {
        callback && callback(data);
    }, (error) => {
        console.error("Error occurred:", error);
    });
}
function populateFilters(callback = null) {
    let data = 'filter={"_index":"ibl-dm.public.dl_catalog_app_course"}&additional_fields=["Job Role","Format","Industry","Free / paid","Certificate"]';
    orgSearch(data, function (data) {
        let facetObject = data.facet;
        for (var key in facetObject) {
            var lower = key.toLowerCase();
            if (lower !== key) {
                facetObject[lower] = facetObject[key];
                delete facetObject[key];
            }
        }
        if (facetObject["audit_allowed"]) {
            facetObject["free_/_paid"] = facetObject["audit_allowed"];
            delete facetObject["audit_allowed"];
        }
        if (facetObject["jobrole"]) {
            facetObject["job_role"] = facetObject["jobrole"];
            delete facetObject["jobrole"];
        }
        const desiredFacets = [
            "Level",
            "Format",
            "Topics",
            "Free / Paid",
            "Language",
            "Certificate",
            "Duration",
        ];
        const filteredObject = {};
        desiredFacets.forEach((facet) => {
            const facetKey = facet.toLowerCase().replace(/ \/ /g, "_/_");
            const sanitizedFacet = facetKey.replace(/ /g, "_");
            if (facetObject[sanitizedFacet]) {
                filteredObject[facet] = facetObject[sanitizedFacet];
            }
        });
        callback(filteredObject);
    });
}
function performSearch(fromInput = false, callback = null) {
    let data = "";
    let urlParams = new URLSearchParams(window.location.search);
    const sortValue = urlParams.get("sort_by");
    if (sortValue) {
        $("#sort-by-select").val(sortValue);
    }
    let value = "";
    if (fromInput) {
        value = $('input[data-name="search"]').val();
        urlParams.delete("q");
        if (urlParams.size > 0) {
            window.history.replaceState({}, "", `${window.location.pathname}?${urlParams}`);
        }
        else {
            window.history.replaceState({}, "", `${window.location.pathname}`);
        }
    }
    else {
        value = urlParams.get("q");
    }
    if (value) {
        $('input[data-name="search"]').val(value);
    }
    if (urlParams.size > 0 || value) {
        let encodedValue = encodeURIComponent(value).replace(/%20/g, "+");
        let currentURL = window.location.href;
        let updatedURL = "";
        if (value) {
            if (currentURL.includes("q=")) {
                updatedURL = currentURL.replace(/(\?|&)q=[^&]*/, "$1q=" + encodedValue);
            }
            else {
                let separator = currentURL.includes("?") ? "&" : "?";
                updatedURL = currentURL + separator + "q=" + encodedValue;
            }
            urlParams.set("q", encodedValue);
        }
        history.pushState({}, document.title, updatedURL);
        clearResults();
        let page = localStorage.getItem("page") || "1";
        data =
            'filter={"_index": "ibl-dm.public.dl_catalog_app_course"}&page_size=12' +
                "&page=" +
                page;
        let params = {};
        params["facet_search"] = [];
        params["facet_flexible_fields"] = [];
        params["facet"] = [];
        const addToFacet = (paramName, prefix, defaultValue = null) => {
            if (paramName === "format") {
                // Handle special case for "format" parameter
                const formatValue = urlParams.get("format");
                if (formatValue === "instructor-led") {
                    const specialFacetValue = `${prefix} false`;
                    params["facet"].push(specialFacetValue);
                    if (data.includes("facet")) {
                        data = data.replace(/facet=(\[[^\]]*\])/, function (match, existingArray) {
                            let updatedArray = existingArray.replace(/\]$/, `,"${specialFacetValue}"]`);
                            return "facet=" + updatedArray;
                        });
                    }
                    else {
                        data = data + `&facet=["${specialFacetValue}"]`;
                    }
                }
                else if (formatValue === "self-paced") {
                    const defaultFacetValue = `${prefix} true`; // Set self_paced=true
                    params["facet"].push(defaultFacetValue);
                    if (data.includes("facet")) {
                        data = data.replace(/facet=(\[[^\]]*\])/, function (match, existingArray) {
                            let updatedArray = existingArray.replace(/\]$/, `,"${defaultFacetValue}"]`);
                            return "facet=" + updatedArray;
                        });
                    }
                    else {
                        data = data + `&facet=["${defaultFacetValue}"]`;
                    }
                }
            }
            else if (urlParams.has(paramName)) {
                const paramValue = urlParams.get(paramName);
                if (paramValue) {
                    const facetValue = `${prefix} ${paramValue}`;
                    params["facet"].push(facetValue);
                    if (data.includes("facet")) {
                        data = data.replace(/facet=(\[[^\]]*\])/, function (match, existingArray) {
                            let updatedArray = existingArray.replace(/\]$/, `,"${facetValue}"]`);
                            return "facet=" + updatedArray;
                        });
                    }
                    else {
                        data = data + `&facet=["${facetValue}"]`;
                    }
                }
            }
            else if (paramName === "language" && defaultValue) {
                var unicodeString = "";
                for (var i = 0; i < defaultValue.length; i++) {
                    var charCode = defaultValue.charCodeAt(i);
                    if (charCode > 127) { // Check if the character is non-English
                        unicodeString += "\\u" + charCode.toString(16);
                    }
                    else {
                        unicodeString += defaultValue[i]; // Keep English characters unchanged
                    }
                }
                defaultValue = unicodeString;
                const defaultFacetValue = `${prefix} ${defaultValue}`;
                params["facet"].push(defaultFacetValue);
                if (data.includes("facet")) {
                    data = data.replace(/facet=(\[[^\]]*\])/, function (match, existingArray) {
                        let updatedArray = existingArray.replace(/\]$/, `,"${defaultFacetValue}"]`);
                        return "facet=" + updatedArray;
                    });
                }
                else {
                    data = data + `&facet=["${defaultFacetValue}"]`;
                }
            }
        };
        // Add parameters to facet
        addToFacet("level", "level");
        addToFacet("audit_allowed", "audit_allowed");
        addToFacet("format", "self_paced");
        addToFacet("topics", "topics");
        addToFacet("language", "language", "en");
        addToFacet("duration", "duration");
        addToFacet("promotion", "promotion");
        addToFacet("subject", "subject");
        let hasFilter = false;
        let hasCertificate = false;
        if (urlParams.size > 0) {
            urlParams.forEach(function (value, key) {
                if (key == "certificate") {
                    hasCertificate = true;
                }
                else {
                    hasFilter = true;
                }
            });
            urlParams.forEach(function (value, key) {
                const values = value.split(",");
                if (key == "level" ||
                    key == "topics" ||
                    key == "language" ||
                    key == "duration" ||
                    key == "promotion" ||
                    key == "subject" ||
                    key == "format" ||
                    key == "audit_allowed") {
                    return;
                }
                if (!hasCertificate) {
                    params["facet"] = params["facet"].concat(values);
                    if (data.includes("facet")) {
                        data = data.replace(/facet=(\[[^\]]*\])/, function (match, existingArray) {
                            let newValue = '"' + value + '"';
                            let updatedArray = existingArray;
                            if (value == "certificate") {
                                return "facet=" + updatedArray;
                            }
                            else {
                                updatedArray = existingArray.replace(/]$/, "," + newValue + "]");
                            }
                            return "facet=" + updatedArray;
                        });
                    }
                    else {
                        if (value !== "certificate")
                            data = data + '&facet=["' + value + '"]';
                    }
                }
                else if (hasCertificate && hasFilter) {
                    params["facet_search"] = params["facet_search"].concat(values);
                    if (params["facet_search"].includes("certificate")) {
                        let certValues = localStorage.getItem("certValues");
                        if (certValues) {
                            if (data.includes("facet_flexible_fields")) {
                                data = data.replace(/facet_flexible_fields=(\[[^\]]*\])/, function (match, existingArray) {
                                    return "facet_flexible_fields=[" + certValues + "]";
                                });
                            }
                            else {
                                data = data + "&facet_flexible_fields=[" + certValues + "]";
                            }
                        }
                    }
                    if (data.includes("facet_search")) {
                        data = data.replace(/facet_search=(\[[^\]]*\])/, function (match, existingArray) {
                            let newValue = '"' + value + '"';
                            let updatedArray = existingArray;
                            if (value == "certificate") {
                                return "facet_search=" + updatedArray;
                            }
                            else {
                                updatedArray = existingArray.replace(/]$/, "," + newValue + "]");
                            }
                            return "facet_search=" + updatedArray;
                        });
                    }
                    else {
                        if (value !== "certificate")
                            data = data + '&facet_search=["' + value + '"]';
                    }
                }
                else {
                    //if only certificate
                    params["facet_search"] = params["facet_search"].concat(values);
                    if (params["facet_search"].includes("certificate")) {
                        let certValues = localStorage.getItem("certValues");
                        if (certValues) {
                            if (data.includes("facet_search")) {
                                data = data.replace(/facet_flexible_fields=(\[[^\]]*\])/, function (match, existingArray) {
                                    return "facet_search=[" + certValues + "]";
                                });
                            }
                            else {
                                data = data + "&facet_search=[" + certValues + "]";
                            }
                        }
                    }
                }
            });
        }
        orgSearch(data, function (data) {
            let result_text = data.total_results > 1 ? "results" : "result";
            $(".num").text(`${data.total_results} ${result_text}`);
            clearPills();
            if (value) {
                if (!pillExists(value)) {
                    addPill(value, "name");
                }
            }
            urlParams.forEach(function (value, key) {
                if (key !== "q") {
                    if (!data["facet_search"]) {
                        data["facet_search"] = [];
                    }
                    const values = value.split(",");
                    values.forEach(function (v) {
                        if (data["facet_search"]) {
                            data["facet_search"].push(v);
                            if (key !== "q") {
                                addPill(v, key);
                            }
                        }
                    });
                }
            });
            callback(data.results, data.total_results, data.num_pages);
        });
    }
    else {
        let currentURL = window.location.href;
        resetSearch();
    }
}
function clearResults() {
    $(".all.grid").empty();
}
function clearPills() {
    $(".search-pill").remove();
}
function pillExists(value) {
    let pills = $(".search-pill");
    for (let i = 0; i < pills.length; i++) {
        if ($(pills[i]).text().trim() === value) {
            return true;
        }
    }
    return false;
}
function addPill(value, type) {
    let pName = value;
    if (type == "audit_allowed") {
        if (value == "true") {
            pName = "Free";
        }
        else {
            pName = "Paid";
        }
    }
    if (value == "audit_allowed") {
        pName = "Free";
    }
    let div = document.createElement("div");
    div.className = "search-pill";
    if (pName == "ja") {
        pName = "Japanese";
    }
    if (pName == "ko") {
        pName = "Korean";
    }
    if (pName == "zh_HANT") {
        pName = "Traditional Chinese";
    }
    if (pName == "zh_HANS") {
        pName = "Simplified Chinese";
    }
    if (pName == "zn_HANS") {
        pName = "Simplified Chinese";
    }
    div.innerHTML = `<div>${pName}</div><div class="x_icon"><i class="fas fa-times"></i></div>`;
    $(".pills").append(div);
    $(div).attr("data-type", type);
    $(div).attr("data-value", value);
}
function resetSearch() {
    $('.search-pill[data-type="name"]').remove();
    $(".course-row").show();
    $('div[data-name="all"]').hide();
}
