"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.timeUpdate = timeUpdate;
const http_1 = require("../http");
const config_1 = require("../config");
const http = new http_1.default();
function timeUpdate(data, successCb = null, errorCb = null) {
    http.post(config_1.default.IBL_AXD.TIME_UPDATE(data.org), data, (_data) => {
        successCb && successCb(_data);
    }, (_error) => {
        errorCb && errorCb(_error);
    }, true, 'IBL_DM_URL');
}
