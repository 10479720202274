"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updatePlatformConfig = updatePlatformConfig;
exports.sendEmail = sendEmail;
exports.postTenant = postTenant;
exports.getCsrfToken = getCsrfToken;
const http_1 = require("../http");
const config_1 = require("../config");
const utils_1 = require("../utils");
const http = new http_1.default();
function updatePlatformConfig(data, callback = null) {
    http.get(config_1.default.IBL_EDX.UPDATE_PLATFORM_CONFIG, data, (data) => {
        callback && callback(data);
    }, (error) => {
        console.error('Error occurred:', error);
    }, false, 'IBL_DM_URL');
}
function sendEmail(data, callback = null) {
    http.post(config_1.default.IBL_EDX.SEND_EMAIL, data, (data) => {
        callback && callback(data);
    }, (error) => {
        console.error('Error occurred:', error);
    }, false, 'IBL_LMS_URL');
}
/**
 * Post tenant
 * @function postTenant
 * @memberof ibledxplatform
 *
 * @see https://github.com/iblai/ibl-dm-multi-services-app/blob/master/USAGE.md#launch-tenant
 *
 * @param {Object} data
 * @param {String} [data.username] platform admin username
 * @param {String} [data.email] platform admin email
 * @param {String} [data.password] platform admin password
 * @param {String} [data.firstname] platform admin firstname
 * @param {String} [data.lastname] platform admin lastname
 * @param {String} [data.role] platform admin role in organization (based on edX user roles)
 * @param {String} [data.key] platform key
 * @param {String} [data.name] platform name
 * @param {String} [data.org] platform org value
 * @param {String} [data.lms_url] platform LMS URL
 * @param {String} [data.cms_url] platform CMS URL
 * @param {String} [data.portal_url] platform WP URL
 * @param {callback} successCb
 * @param {callback} errorCb
 *
 * @example <caption>Post tenant</caption>
 * This usage:
 *
 * ```js
 * ibledxplatform.postTenant(
 *    {
 *      username: "adminUser",
 *      email: "admin@example.com",
 *      password: "securePassword123!",
 *      firstname: "Admin",
 *      lastname: "User",
 *      role: "role",
 *      key: "platformKey123",
 *      name: "PlatformName",
 *      org: "OrgValue",
 *      lms_url: "https://lms.example.com",
 *      cms_url: "https://cms.example.com",
 *      portal_url: "https://portal.example.com",
 *    },
 *    (data) => console.log(data),
 *    (error) => console.error(error)
 * )
 * ```
 */
function postTenant(data, successCb, errorCb) {
    http.post(config_1.default.IBL_EDX.POST_TENANT, data, (data) => {
        successCb && successCb(data);
    }, (error) => {
        errorCb && errorCb(error);
    }, false, 'IBL_LMS_URL');
}
/**
 * Get Edx Csrf Token
 * @function getCsrfToken
 * @memberof ibledxplatform
 *
 * @param {number} [expiryTime] - Optional. Minutes before csrfToken expires. If not provided, a default value can be used or no expiration adjustment will be made.
 *
 * @example <caption>Gets the Csrf Token from Edx with a specific expiry time</caption>
 * This usage with specified expiry time:
 *
 * ```js
 * ibledxplatform.getCsrfToken(10); // Set expiry to 10 minutes
 * ```
 *
 * @example <caption>Gets the Csrf Token from Edx without specifying an expiry time</caption>
 * This usage without specifying expiry time:
 *
 * ```js
 * ibledxplatform.getCsrfToken(); // No expiry time specified
 * ```
 */
function getCsrfToken(expiryTime = null, successCb = null, errorCb = null) {
    let url = config_1.default.IBL_EDX.GET_CSRF_TOKEN;
    const csrfTokenSuccessCallback = (data) => {
        const baseDomain = (0, utils_1.removeSubdomains)();
        if (data.csrfToken) {
            (0, utils_1.setCookie)(data.csrfToken, baseDomain, expiryTime ? expiryTime : 3);
            successCb && successCb(data);
        }
        else {
            errorCb && errorCb("Response doesn't contain csrfToken");
        }
    };
    const csrfTokenErrorCallback = (error) => {
        console.error(`Error fetching csrfToken : ${error}`);
        errorCb && errorCb(`Error fetching csrfToken : ${error}`);
    };
    http.get(url, null, csrfTokenSuccessCallback, csrfTokenErrorCallback, false, 'IBL_LMS_URL');
}
