"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMfeContext = getMfeContext;
const http_1 = require("../http");
const config_1 = require("../config");
const http = new http_1.default();
function getMfeContext(successCb, errorCb) {
    http.get(config_1.default.IBL_EDX.GET_MFE_CONTEXT, null, (data) => {
        successCb && successCb(data);
    }, (error) => {
        errorCb && errorCb(error);
    }, false, 'IBL_LMS_URL');
}
